import { ENTITY_STATUSES } from "const";
import { IProfile, ISelectOption, ISubscription } from "./common";

export interface IVerticalProfile<EntityType, EntityTypeProfile> {
  id: string;
  entity: EntityType;
  entityTypeProfiles: EntityTypeProfile[];
  memberOfConsortiums: IConsortiumTypeProfile[];
  keySources: string[] | null;
  partnerships: IPartnership[];
  vertical: string;
}

export type IEntityTypeProfile = {
  entityType?: ENTITY_TYPE;
  status?: ENTITY_STATUSES;
  updatedAt?: string | null;
  createdAt?: string | null;
  aliases: string[];
} & (IConsortiumTypeProfile &
  IUniversityTypeProfile &
  IEnterpriseUserTypeProfile &
  IOrganizationTypeProfile &
  IGovernmentTypeProfile &
  IInvestorTypeProfile &
  ICenterTypeProfile);

interface IEntityGeneric<EntityTypeProfile> {
  id: string;
  name: string;
  aliases: string[];
  logo: string | null;
  city: string | null;
  state: string | null;
  inCountry: ICountryEntity[];
  description: string | null;
  linkedIn: string | null;
  founded: number | null;
  hasSubscription: ISubscription[];
  lastUpdatedBy: string | null;
  lat: number | null;
  lng: number | null;
  status: ENTITY_STATUSES | null | string;
  website: string | null;
  employeeRange: string | null;
  employees: IProfile[];
  updatedAt: string;
  createdAt: number;
  color: string | null | undefined;
  symbol?: string;
  ofBusinessType: ITagEntity[];
  __typename: string;
  vertical: string[];
  verticalProfiles: IVerticalProfile<IEntity, EntityTypeProfile>[];
}

export type IEntity = IEntityGeneric<IEntityTypeProfile>;

export interface IConsortiumTypeProfile {
  id: string;
  goals: string;
  entityType: ENTITY_TYPE;
  verticalProfiles: IVerticalProfile<
    IConsortiumEntity,
    IConsortiumTypeProfile
  >[];
  consortiumHasMembers: IVerticalProfile<IEntity, IEntityTypeProfile>[];
}

export type IConsortiumEntity = IEntityGeneric<IConsortiumTypeProfile>;

interface IGrantEntity {
  id?: string;
  name: string;
  hasProjectProjects: IProjectEntity[];
  offeredAwardProjects: IProjectEntity[];
  year: number;
}

interface IProjectEntity {
  id?: string;
  name: string;
  governmentEntityLead: IGovernmentEntity[];
  grantsHasProject: IGrantEntity[];
  grantsOfferedAward: IGrantEntity[];
  organizationLead: IOrganizationEntity[];
  publicDescription: string;
  universityLead: IUniversityEntity[];
}

export interface IAggregate<T> {
  count: number;
  node: T;
}

export interface IFundingRoundEntity {
  startDate: string | null;
  source: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  fxRate: number | null;
  status: ENTITY_STATUSES | null;
  id: string | null;
  type?: string | null;
  investors: IInvestorTypeProfile[];
  organization: string | null;
  organizationHasRound: IOrganizationTypeProfile[];
  localCurrency: string | null;
  fundingLocal: number | null;
  fundingUsd: number | null;
  isConfirmed: boolean | null;
}

export interface IInvestorTypeEntity {
  id: string;
  name: string;
  description: string;
}

export interface IRegionEntity {
  id: string;
  name: string;
}

export interface IClassificationEntity {
  __typename?: string;
  id: string | undefined;
  name: string | undefined;
  description?: string;
  childOf?: IPrimaryClassificationEntity[];
  organizationsInClassification?: IOrganizationEntity[];
}

export interface IPrimaryClassificationEntity {
  __typename?: string;
  id: string | undefined;
  name: string | undefined;
  description?: string;
  parentOf?: IClassificationEntity[];
}

export interface IBusinessTypeEntity {
  id: string;
  description?: string;
  organizations: IOrganizationEntity[];
  name: string;
}
export interface ITagEntity {
  id: string;
  name: string;
  description: string;
  vertical: string;
  tagType: string;
}

export interface IOffersTags {
  id: string;
  name: string;
  description: string;
}

export interface IQuantumTechInterest {
  id: string | undefined;
  name: string | undefined;
  description?: string;
  enterpriseUsers?: IEnterpriseUserEntity[];
}

export interface IPartnership {
  id: string;
  verticalProfiles: IVerticalProfile<IEntity, IEntityTypeProfile>[];
  status: string;
  vertical: string;
  date: string;
  description: string;
  source: string;
  updatedAt: string;
  createdAt: string;
  lastUpdatedBy: string;
}

export interface IEnterpriseUserTypeProfile {
  entityType: ENTITY_TYPE;
  interests: IQuantumTechInterest[] | null;
  sector: ISectorEntity[];
  interestInVertical: string | null;
  verticalProfiles: IVerticalProfile<
    IEnterpriseUserEntity,
    IEnterpriseUserTypeProfile
  >[];
}

export type IEnterpriseUserEntity = IEntityGeneric<IEnterpriseUserTypeProfile>;

export interface IStateEntity {
  name: string;
  abbreviation: string;
  lat: number;
  lng: number;
  capital: string;
  country: ICountryEntity[];
}

export interface IOrganizationTypeProfile {
  id: string;
  entityType: ENTITY_TYPE;
  acquiredBy: IOrganizationTypeProfile[] | null;
  acquisitions: IOrganizationTypeProfile[] | null;
  acquiredByConnection?: {
    edges: {
      node: IOrganizationTypeProfile;
      properties: {
        date: string;
        description: string;
        source: string;
      };
    }[];
  };
  // hasSubscription: ISubscription[];
  fundingRounds?: Partial<IRoundEntity>[];
  fundingRoundsAggregate: IAggregate<{
    fundingUsd: {
      average: string;
      max: string;
      min: string;
      sum: string;
    };
  }>;
  spinoutFrom: IUniversityTypeProfile[];
  inClassification: Array<IClassificationEntity>;
  ofType: Partial<IBusinessTypeEntity>[];
  offers: Partial<IOffersTags>[];
  raised: number;
  techDetail: string;
  // employees: IProfile[];
  facilitatesAccessToOrganizations: IOrganizationTypeProfile[];
  organizationFacilitatesAccessTo: IOrganizationTypeProfile[];
  hasQubitImplementations: IClassificationEntity[];
  // color: string | null | undefined;
  hasQubitUpdatesConnection: {
    edges: {
      node: {
        number: number;
        year: number;
      };
    }[];
  };
  hasQubitUpdates: IQubitNumber[];
  verticalProfiles: IVerticalProfile<
    IOrganizationEntity,
    IOrganizationTypeProfile
  >[];
  cloudOffering: string;
  simulatorOffering: string;
  consultingOffering: string;
  cpuOffering: boolean;
  gpuOffering: boolean;
  softwareOffering: string;
  quantumLibrary: string;
  quantumAlgorithms: string;
  quantumCircuit: string;
  assemblyLanguage: string;
  offersQpu: boolean;
  offersQcaas: boolean;
  quantumCloudLinks: string[];
  isQubitInfoVerified: boolean;
}

export type IOrganizationEntity = IEntityGeneric<IOrganizationTypeProfile>;

export interface IQubitNumber {
  id: string | null;
  number: number | null;
  year: number | string;
  source: string | null;
  qubitTechnology: string | ISelectOption;
  compatibleSDKs: string[];
  qpu: string;
  yearOfRelease: number | null;
  physicalQubits: number | null;
  logicalQubit: string;
  algorithmicQubit: string;
  quantumVolume: number | null;
  clops: number | null;
  ioLines: number | null;
  onChipMemory: string;
  operatingTemperature: string;
  powerConsumption: number | null;
  scalable: number | null;
  t1AmplitudeDecoherenceTime: string;
  t2EchoPhaseDecoherenceTime: string;
  t3Ramsey: number | null;
  q1Individual: string;
  q1Parallel: string;
  q2: string;
  price: string;
  notes: string;
  readoutSpam: string;
  nativeGates: string;
}

export interface IRegion {
  id: string;
  name: string;
}

export interface IBusinessType {
  id: string;
  organizations?: IOrganizationEntity[];
  type: string;
}

export type VariableType = {
  limit?: number;
  offset?: number;
  sort?: {};
  where?: any;
  search?: string | null;
  fulltext?: string;
  phrase?: string;
  updateType?: string;
  types?: string;
  isAdmin?: boolean;
  isAuth?: boolean;
};

export interface IGovernmentTypeProfile {
  entityType: ENTITY_TYPE;
  interestInVertical: string | null;
  // projects: IProjectEntity[]; //TODO
  verticalProfiles: IVerticalProfile<
    IGovernmentEntity,
    IGovernmentTypeProfile
  >[];
}

export type IGovernmentEntity = IEntityGeneric<IGovernmentTypeProfile>;

export interface IRoundEntity {
  __typename?: string;
  id: string;
  name: string;
  status: string;
  source: string;
  startDate: string;
  fxRate: string;
  investors: IInvestorTypeProfile[];
  organizationHasRound: IOrganizationTypeProfile[];
  type: string;
  fundingLocal: number;
  lastUpdatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  fundingUsd: number;
  entityType: ENTITY_TYPE[];
}

export interface IUniversityTypeProfile {
  // leadOfProjects: IProjectEntity[];
  entityType: ENTITY_TYPE;
  spinouts: IOrganizationTypeProfile[];
  parentOf: ICenterTypeProfile[];
  interestInVertical: string | null;
  focusAreas: IFocusAreaEntity[];
  verticalProfiles: IVerticalProfile<
    IUniversityEntity,
    IUniversityTypeProfile
  >[];
}

export type IUniversityEntity = IEntityGeneric<IUniversityTypeProfile>;

export interface IInvestorTypeProfile {
  id?: string;
  entityType: ENTITY_TYPE;
  centerAndPartners: ICenterEntity[];
  investedIn: IFundingRoundEntity[];
  mandate: string | null;
  ofType: IInvestorTypeEntity[];
  portfolio: IEntityTypeProfile[];
  interestInVertical: string | null;
  investedInConnection?: {
    edges: [
      {
        properties: {
          isLead: number;
        };
        node: {
          id: string;
        };
      }
    ];
  };
  verticalProfiles: IVerticalProfile<IInvestorEntity, IInvestorTypeProfile>[];
  totalNumberOfLeadInvestments: string;
  totalNumberOfInvestments: string;
}

export type IInvestorEntity = IEntityGeneric<IInvestorTypeProfile>;

export interface IConnect<TNode = { id: string | null }> {
  connect: {
    where?: { node: TNode } | {};
  };
}

export interface IPartnerConnect<TNode = { id: string | null }> {
  connect: {
    Organization?: {
      where?: { node: TNode } | {};
    };
    University?: {
      where?: { node: TNode } | {};
    };
    Investor?: {
      where?: { node: TNode } | {};
    };
    GovernmentEntity?: {
      where?: { node: TNode } | {};
    };
    EnterpriseUser?: {
      where?: { node: TNode } | {};
    };
    Center?: {
      where?: { node: TNode } | {};
    };
  };
}

export class SelectOption {
  value: string = "";
  label: string = "";
  optionalData?: any = {};
  logo?: string;
  constructor(
    value: string,
    label: string,
    optionalData: any = null,
    logo?: string
  ) {
    this.label = label;
    this.value = value;
    this.optionalData = optionalData;
    this.logo = logo;
  }
}

export interface IInvestorRoundEntity {
  id: string;
  fundingUsd: number | null | string;
  createdAt: number;
  date: string;
  fxRate: string;
  investors: IInvestorEntity[];
  organization: IOrganizationEntity[];
  type: string;
}

export interface IFocusAreaEntity {
  id: string;
  name: string;
  centers: ICenterEntity[];
  createdAt: number;
  status: string;
  updatedAt: number;
}

export interface ICenterTypeProfile {
  entityType: ENTITY_TYPE;
  focusAreas: IFocusAreaEntity[];
  funding: number | null;
  fundingSrc: string[];
  parent: string | null;
  childOf: IUniversityTypeProfile[];
  interestInVertical: string | null;
  verticalProfiles: IVerticalProfile<ICenterEntity, ICenterTypeProfile>[];
}

export type ICenterEntity = IEntityGeneric<ICenterTypeProfile>;

export interface ICountryEntity {
  __typename: string;
  id: string;
  name: string;
  region: IRegion[];
  fundingRounds: IGovernmentFundingRoundEntity[];
  verticalFundings: IVerticalFunding[];
  lat: number | null;
  lng: number | null;
  fundingUsdTotal: number;
  fundingLocalTotal: number;
  entities: Array<
    (
      | IUniversityEntity
      | IOrganizationEntity
      | IEnterpriseUserEntity
      | IGovernmentEntity
      | ICenterEntity
      | IInvestorEntity
    ) & { __typename: string }
  >;
  states: IStateEntity[] | null;
}

export interface IVerticalFunding {
  id: string;
  nationalStrategy: string;
  commentary: string;
  vertical: string;
  source: ISource[] | null;
}

export interface IGovernmentFundingRoundEntity {
  id: string;
  createdAt: string;
  fundingUsd: number;
  fundingLocal: number;
  fxRate: number;
  lastUpdatedBy: string | null;
  lat: number | null;
  lng: number | null;
  source: string | null;
  updatedAt: string;
  localCurrency: ISelectOption | null | undefined;
  startDate: Date | null;
  fundingInfo: string | null;
  isActive: boolean | null;
  deliveryPeriod: number | null;
  vertical: string;
  status: ENTITY_STATUSES;
}

export interface ISource {
  id: string;
  name: string;
  url: string;
  status: string;
}

export interface IGlobalSearchEntities {
  universities: {
    score: number;
    entity: Partial<IUniversityEntity>;
  }[];
  governmentEntities: {
    score: number;
    entity: Partial<IGovernmentEntity>;
  }[];
  centers: {
    score: number;
    entity: Partial<ICenterEntity>;
  }[];
  consortiums: {
    score: number;
    entity: Partial<IConsortiumEntity>;
  }[];
  organizations: {
    score: number;
    entity: Partial<IOrganizationEntity>;
  }[];
  investors: {
    score: number;
    entity: Partial<IInvestorEntity>;
  }[];
  enterpriseUsers: {
    score: number;
    entity: Partial<IEnterpriseUserEntity>;
  }[];
}
export interface ISectorEntity {
  id: string;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  enterpriseUsers: IEnterpriseUserEntity[];
}

export interface IGraphingTool {
  roundCount: number;
  month: string;
  classification: string;
  total: number;
}

export interface IAnalysisTool {
  year: number;
  classification: string;
  total: number;
}

export interface IUseCasesEntity {
  id: string;
  name: string | null;
  description: string | null;
  industry: string | null;
  problemDomain: string | null;
  approaches: string[];
  hardware: string[];
  currentStatus: string | null;
  companies: IOrganizationTypeProfile[];
  mainSource: string | null;
  status: ENTITY_STATUSES;
  vertical: string;
}

export enum ENTITY_TYPE {
  ORGANIZATION = "ORGANIZATION",
  UNIVERSITY = "UNIVERSITY",
  CENTER = "CENTER",
  INVESTOR = "INVESTOR",
  BRAND = "BRAND",
  CONSORTIUM = "CONSORTIUM",
  GOVERNMENT_AGENCY = "GOVERNMENT_AGENCY",
  NATIONAL_LAB = "NATIONAL_LAB",
}
