import React, { useState, useEffect, useRef, memo } from "react";
import usePresignedUrl from "hooks/usePresignedUrl";
import tqiIcon from "assets/images/quantumFavNew.svg";
import dtiIcon from "assets/images/dtFavNew.svg";
import spaceIcon from "assets/images/spaceFavNew.svg";
import climateIcon from "assets/images/climateFavNew.svg";
import config from "config";

interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src?: string | undefined;
}

const logoPlaceholder =
  config.VERTICAL === "METAVERSE"
    ? dtiIcon
    : config.VERTICAL === "SPACE"
    ? spaceIcon
    : config.VERTICAL === "CLIMATE"
    ? climateIcon
    : tqiIcon;

const SignedImg = ({ alt, crossOrigin, src, ...rest }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLImageElement>(null);

  const { imageUrl, loading } = usePresignedUrl(isVisible ? src : null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <img
      ref={ref}
      alt={alt || ""}
      src={loading ? logoPlaceholder || "" : imageUrl || logoPlaceholder || ""}
      crossOrigin={crossOrigin || ""}
      onError={(e) => {
        e.currentTarget.src = logoPlaceholder || "";
      }}
      {...rest}
    />
  );
};

export default memo(SignedImg);
